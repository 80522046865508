<template>
  <div class="register-list">
    <el-table
        :data="list"
        border
        stripe
        style="width: 100%"
    >
      <el-table-column prop="schoolName" label="学校"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="subject" label="科目"></el-table-column>
      <el-table-column prop="grade" label="年级"></el-table-column>
      <el-table-column prop="sex" label="性别"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
    </el-table>
    <div style="margin: 20px 0">
      <el-pagination
          @size-change="getList"
          @current-change="getList"
          :current-page.sync="params.pageNum"
          :page-size.sync="params.pageSize"
          :page-sizes="[10, 20, 30, 50]"
          layout="sizes, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from '../../request'
import { Table, TableColumn, Pagination } from 'element-ui'
export default {
  name: 'registerList',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      request({
        url: '/api/guest/school/project/pagination',
        method: 'get',
        params: this.params
      }).then(res => {
        this.total = res.data.total
        this.list = res.data.records.map(i => {
          return {
            ...i,
            sex: i.gender ? '男' : '女'
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register-list{
  width: 90%;
  margin: 50px auto 0 auto;
}
</style>
